<template>
  <div class="mt-5 mb-5 fade-transition">
    <v-card flat>
      <!-- for small screen -->
      <div v-if="$vuetify.breakpoint.smAndDown">
        <v-row>
          <v-col class="overline ml-5" cols="12">
            <span class="subtitle-1">
              <h4>
                <span>PERIOD: </span>
                <span class="green--text font-weight-bold subtitle-1">{{
                  currentReview ? currentReview.period : "N/A"
                }}</span>
              </h4>
            </span>
            <span class="subtitle-1">
              <h4>
                <span>STAGE: </span>
                <span class="green--text font-weight-bold subtitle-1">{{
                  currentReview ? currentReview.currentStage : "N/A"
                }}</span>
              </h4>
            </span>
            <v-divider />
          </v-col>
          <v-col
            cols="12"
            class="d-flex flex-column justify-center text-center overline"
          >
            <span class="text-h4 green--text">
              {{ daysRemaining }}
            </span>
            <h4 class="subtitle-2">DAYS REMAINING</h4>
            <v-divider />
          </v-col>
          <v-col class="ml-5" cols="12">
            <span class="subtitle-1">
              <h4>
                <span>REVIEWER: </span>
                <span class="green--text font-weight-bold subtitle-1">{{
                  currentReview ? currentReview.reviewerNames : "N/A"
                }}</span>
              </h4>
            </span>
            <span class="subtitle-1">
              <h4>
                <span>JOB TITLE: </span
                ><span class="green--text font-weight-bold subtitle-1">{{
                  currentReview ? currentReview.reviewerJobTitle : "N/A"
                }}</span>
              </h4>
            </span>
          </v-col>
        </v-row>
      </div>
      <!-- end small screen -->
      <!-- for medium and large screens -->
      <div v-else>
        <v-row>
          <v-col class="overline ml-5" cols="3" md="3" sm="12">
            <span class="subtitle-1">
              <h4>
                <span>PERIOD: </span>
                <span class="green--text font-weight-bold subtitle-1">{{
                  currentReview ? currentReview.period : ""
                }}</span>
              </h4>
            </span>
            <span class="subtitle-1">
              <h4>
                <span>STAGE: </span>
                <span class="green--text font-weight-bold subtitle-1">{{
                  currentReview ? currentReview.currentStage : ""
                }}</span>
              </h4>
            </span>
          </v-col>
          <v-divider vertical />
          <v-col
            cols="3"
            md="3"
            sm="12"
            class="d-flex flex-column justify-center text-center overline"
          >
            <span class="text-h4 green--text">
              {{ daysRemaining }}
            </span>
            <h4 class="subtitle-2">DAYS REMAINING</h4>
          </v-col>
          <v-divider vertical />
          <v-col cols="5" md="5" sm="12">
            <span class="subtitle-1">
              <h4>
                <span>REVIEWER: </span>
                <span class="green--text font-weight-bold subtitle-1">{{
                  currentReview ? currentReview.reviewerNames : "N/A"
                }}</span>
              </h4>
            </span>
            <span class="subtitle-1">
              <h4>
                <span>JOB TITLE: </span
                ><span class="green--text font-weight-bold subtitle-1">{{
                  currentReview ? currentReview.reviewerJobTitle : "N/A"
                }}</span>
              </h4>
            </span>
          </v-col>
        </v-row>
      </div>
    </v-card>
  </div>
</template>

<script>
import AppraisalMixin from "../AppraisalMixin";

export default {
  name: "AppraisalHeader",
  mixins: [AppraisalMixin],

  data: function () {
    return {
      isMobile: false,
      dialog: false,
    };
  },
  computed: {
    Status() {
      return this.currentReview ? this.currentReview.periodAPI.closed : {};
    },
    getcolor() {
      if (this.Status === true) {
        return "red";
      } else {
        return "green";
      }
    },
    closed() {
      if (this.Status === true) {
        return "closed";
      } else {
        return " current";
      }
    },
    periods() {
      return this.$store.getters["appraisal/periods"];
    },
  },
  methods: {
    attach: function (code) {
      this.dialog = true;
    },
    closeDialog: function (val) {
      this.dialog = val;
      this.PassComments = this.Comments;
    },
  },
};
</script>

<style scoped></style>
