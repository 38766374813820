<template>
  <v-container fluid grid-list-xl>
    <v-row>
      <v-col cols="12">
        <v-sheet elevation="1" tile>
          <v-row class="mt-1">
            <v-col cols="12" md="6">
              <v-card elevation="0" tile>
                <v-card-title class="overline justify-center">
                  PERIOD: {{ currPeriod ? currPeriod.code : "" }}
                  <v-spacer />
                  STAGE:
                  {{ currentReview ? currentReview.currentStage : "N/A" }}
                  <v-chip x-small dark :color="getcolor">
                    {{ periodStatus }}
                  </v-chip>
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col>
                      <div class="d-flex justify-left">
                        <vue-ellipse-progress
                          :determinate="false"
                          :angle="-90"
                          :size="120"
                          :progress="Math.round(OverallScore(currentReview))"
                          :thickness="6"
                          lineMode="normal"
                          emptyColor="#AEAEAE"
                          color=" green"
                          :legend="false"
                          dot="10 #113B08"
                          animation="rs 700 1000"
                          fontSize="1.0rem"
                          :loading="false"
                          :no-data="false"
                          :gap="5"
                        >
                          <span slot="legend-caption">
                            <h6 class="text-h6 font-weight-bold green--text">
                              {{ OverallScore(currentReview) }}%
                            </h6>
                            <span
                              class="subtitle-2 font-weight-light"
                              style="color: black"
                              >Final Score</span
                            >
                          </span>
                        </vue-ellipse-progress>
                      </div>
                    </v-col>
                    <v-col class="mt-4">
                      <v-list dense max-width="90%">
                        <h4 class="subtitle-1 orange--text">
                          {{ usedDays }} days completed
                        </h4>
                        <v-progress-linear
                          color="orange"
                          :value="usedDays"
                        ></v-progress-linear>
                        <h5 class="subtitle-1 green--text">
                          {{ daysRemaining }} days remaining
                        </h5>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-text class="">
                  <v-row>
                    <v-col
                      class="d-flex flex-column justify-center text-center overline"
                    >
                      <h1 class="text-h4 green--text">
                        {{ Objectives }}
                      </h1>
                      <h4 class="subtitle-2">Objectives</h4>
                    </v-col>
                    <v-divider vertical />
                    <v-col
                      class="d-flex flex-column justify-center text-center overline"
                    >
                      <h1 class="text-h4 blue--text">
                        {{ KPIs }}
                      </h1>
                      <h4 class="subtitle-2">KPIs</h4>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" v-if="$vuetify.breakpoint.smAndDown">
              <v-divider />
            </v-col>
            <v-divider v-else vertical />

            <v-col cols="12" md="5">
              <v-card elevation="0" tile>
                <v-card-title class="overline"> Performance </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col
                      class="d-flex flex-column justify-center text-center overline"
                    >
                      <h1 class="text-h4 green--text">
                        {{ MyScore(currentReview) }}%
                      </h1>
                      <h4 class="subtitle-2">My Score</h4>
                    </v-col>
                    <v-divider vertical />
                    <v-col
                      class="d-flex flex-column justify-center text-center overline"
                    >
                      <h1 class="text-h4 blue--text">
                        {{ SupervisorScore(currentReview) }}%
                      </h1>
                      <h4 class="subtitle-2">Supervisor Score</h4>
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-divider />
                <v-card-text>
                  <v-row>
                    <v-col class="ml-3">
                      <vue-ellipse-progress
                        :progress="percentObjectivesRated"
                        :angle="-90"
                        color="#22335E"
                        emptyColor="#AEAEAE"
                        :thickness="6"
                        lineMode="normal"
                        :legend="true"
                        animation="rs 700 1000"
                        :noData="false"
                        :loading="false"
                        fontColor="transparent"
                        :half="false"
                        :gap="10"
                        dot="10 #22335E"
                        fontSize="1.0rem"
                        :determinate="false"
                        :size="100"
                      >
                        <template v-slot:legend-caption>
                          <p slot="legend-caption">
                            {{ ObjectivesRated }}/{{ Objectives }} <br />
                            Objectives Rated
                          </p>
                        </template>
                      </vue-ellipse-progress>
                    </v-col>
                    <v-col>
                      <vue-ellipse-progress
                        :progress="kpisRated"
                        :angle="-90"
                        color="#E67E22"
                        emptyColor="#AEAEAE"
                        :thickness="6"
                        lineMode="normal"
                        :legend="true"
                        :legend-value="50"
                        animation="rs 700 1000"
                        :noData="false"
                        :loading="false"
                        fontColor="transparent"
                        :half="false"
                        :gap="10"
                        dot="10 #E67E22"
                        fontSize="1.0rem"
                        :determinate="false"
                        :size="100"
                      >
                        <template v-slot:legend-caption>
                          <p slot="legend-caption">
                            {{ RatedKPIs }}/{{ KPIs }} <br />
                            KPIS Rated
                          </p>
                        </template>
                      </vue-ellipse-progress>
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-col>

            <v-col cols="12" v-if="$vuetify.breakpoint.smAndDown">
              <v-divider />
            </v-col>
          </v-row>
        </v-sheet>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import moment from "moment";
import AppraisalMixin from "../AppraisalMixin";
export default {
  name: "AppraisalDashboard",
  mixins: [AppraisalMixin],
  data: () => ({}),
  beforeRouteEnter(to, from, next) {
    next((v) => {
      v.$store.dispatch("appraisal/getPeriods");
      v.$store.dispatch("appraisal/getReviews");
      v.$store.dispatch("appraisal/getKpi");
      v.$store.dispatch("appraisal/getReviewObj");
      v.$store.commit("appraisal/SET_REVIEW", false);
    });
  },
  computed: {
    Objectives: function () {
      return this.stats.objectives;
    },
    KPIs: function () {
      return this.stats.kpis;
    },
    reviews() {
      return this.$store.getters["appraisal/reviews"];
    },
    periods() {
      return this.$store.getters["appraisal/periods"];
    },
    KpisToRate() {
      return this.$store.getters["appraisal/kpi"];
    },
    currPeriod() {
      return this.periods.filter((p) => p.current).shift();
    },
    usedDays() {
      return this.currPeriod
        ? moment(this.currPeriod).diff(
            moment(this.currPeriod.startingDate),
            "days"
          )
        : 0;
    },
    kpisRated() {
      var count = 0;
      this.KpisToRate.forEach(function (e) {
        if (e.selfRatingScore !== "") {
          count++;
        }
      });
      var percentageKpis = Number(Math.round((count / this.KPIs) * 100));
      return percentageKpis;
    },
    percentObjectivesRated() {
      var percentageObjectives = Number(
        Math.round((this.ObjectivesRated / this.Objectives) * 100)
      );
      return percentageObjectives;
    },
    RatedKPIs() {
      var count = 0;
      this.KpisToRate.forEach(function (e) {
        if (e.selfRatingScore !== "") {
          count++;
        }
      });
      return count;
    },
    ObjectivesRated() {
      var count = 0;
      var ratedObjectives = 0;
      this.groups.forEach((group) => {
        group.reviewObjectives.forEach((obj) => {
          count = obj.reviewKPI.filter((kpi) => {
            if (kpi.selfRatingScore !== "") {
              return true;
            }
            return false;
          }).length;
          count++;
          if (count > 1) {
            ratedObjectives++;
          }
        });
      });

      return ratedObjectives;
    },
    currentReview() {
      return this.currPeriod
        ? this.reviews.filter((f) => f.period === this.currPeriod.code).shift()
        : null;
    },
    groups() {
      return this.currentReview ? this.currentReview.reviewGroup : [];
    },
    objectives() {
      let objectives = [];
      this.groups.forEach((group) => {
        objectives = [...objectives, ...group.reviewObjectives];
      });
      return objectives;
    },
    stats() {
      const stats = {
        objectives: 0,
        kpis: 0,
        activities: 0,
      };
      this.groups.forEach((grp) => {
        grp.reviewObjectives.forEach((ob) => {
          stats.objectives += 1;
          ob.reviewKPI.forEach((k) => {
            stats.kpis += 1;
          });
        });
      });
      return stats;
    },
    Status() {
      return this.currentReview ? this.currentReview.periodAPI.closed : {};
    },
    ReviewKpi() {
      return this.objectives
        ? this.objectives.filter((i) => i.reviewKPI.length !== 0)
        : [];
    },
    getcolor() {
      if (this.Status === true) {
        return "red";
      } else {
        return "green";
      }
    },
    periodStatus() {
      if (this.Status === true) {
        return "closed";
      } else {
        return " current";
      }
    },
  },
  methods: {},
};
</script>

<style scoped>
@media (min-width: 960px) {
  .flex.md3.lg3-custom {
    width: calc(100% / 3);
    max-width: calc(100% / 3);
    flex-basis: calc(100% / 3);
  }
  .font {
    font-family: "Times New Roman";
    font-size: 15px;
  }
}
</style>
